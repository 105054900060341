import { all, takeEvery, put, call } from 'redux-saga/effects'
import { startLoading, stopLoading } from '../utility'

import { loadOrders, updateOrderStatus, loadRecentOrders } from '../../services/orders'
import actions from './actions'

export function* LOAD_ORDERS({ payload: { query } }) {
  startLoading('orders/SET_STATE')
  const queryParams = query || {}
  const orders = yield call(() => loadOrders(queryParams))
  yield put({
    type: 'orders/SET_STATE',
    payload: { orders: orders && orders.length > 0 ? orders : [] },
  })
  stopLoading('orders/SET_STATE')
}

export function* UPDATE_ORDER_STATUS({ payload: { status, orderID } }) {
  console.log('status', status, orderID)
  if (!status) {
    return
  }
  startLoading('orders/SET_STATE')
  yield call(() => updateOrderStatus(orderID, status))
  stopLoading('orders/SET_STATE')
}

export function* LOAD_RECENT_ORDERS() {
  startLoading('orders/SET_STATE')
  const recentOrders = yield call(() => loadRecentOrders())
  if (recentOrders) {
    yield put({ type: 'orders/SET_STATE', payload: { recentOrders } })
  }
  stopLoading('orders/SET_STATE')
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_RECENT_ORDERS, LOAD_RECENT_ORDERS),
    takeEvery(actions.UPDATE_ORDER_STATUS, UPDATE_ORDER_STATUS),
    takeEvery(actions.LOAD_ORDERS, LOAD_ORDERS),
  ])
}
