import actions from './actions'

const initialState = {
  brokers: [],
  loading: false,
  selectedBroker: { firstName: '', lastName: '', phoneNumber: '', email: '' },
  searchQueryBrokers: '',
  searchBrokersList: [],
}

export default function brokersReducer(state = initialState, action) {
  console.log('user brokers', action, state)
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
