import { all, takeEvery, put, call } from 'redux-saga/effects'

import { loadBrokers, loadSingleBroker, searchBrokers } from '../../services/brokers'
import { startLoading, stopLoading } from '../utility'

import actions from './actions'

export function* LOAD_BROKERS() {
  startLoading(actions.SET_STATE)
  const brokers = yield call(loadBrokers)
  if (brokers && brokers.length > 0) {
    console.log('brokers', brokers)
    yield put({ type: 'brokers/SET_STATE', payload: { brokers } })
  }
  stopLoading(actions.SET_STATE)
}

export function* LOAD_BROKER({ payload: { brokerID } }) {
  startLoading('brokers/SET_STATE')
  const selectedBroker = yield call(() => loadSingleBroker({ brokerID }))
  yield put({
    type: 'brokers/SET_STATE',
    payload: { selectedBroker },
  })
  stopLoading('brokers/SET_STATE')
}

export function* SEARCH_BROKER({ payload: { query } }) {
  console.log('search broker', query)
  startLoading('brokers/SET_STATE')
  yield put({ type: actions.SET_STATE, payload: { searchQuery: query }})
  const searchBrokenList = yield call(() => searchBrokers(query))
  yield put({ type: 'users/SET_STATE', payload: { searchBrokenList } })
  stopLoading('brokers/SET_STATE')
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_BROKERS, LOAD_BROKERS),
    takeEvery(actions.LOAD_BROKER, LOAD_BROKER),
    takeEvery(actions.SEARCH_BROKER, SEARCH_BROKER)])
}
