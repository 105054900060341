import { notification } from 'antd'
import { BASE_URL, authHeaders, methodType, urlParams } from './config'
import { storageHelper, categoryHelper } from './helpers'
import { currentAccount } from './user'

export const loadCategories = async (query = {}) => {
  const categories = storageHelper.arrayFromStorage('categories')
  if (categories && categories.length > 0) {
    return categories
  }
  const path = 'all?'
  const url = `${BASE_URL.category}${path}${urlParams(query)}`
  const response = await fetch(url, {
    method: methodType.get,
    headers: authHeaders(await currentAccount()),
  }).catch(error => {
    notification.warning({ description: String(error) })
    return false
  })
  try {
    const responseJson = await response.json()
    console.log('loaded categories', responseJson.categories)
    storageHelper.arrayToStorage('categories', responseJson.categories)
    return responseJson.categories || []
  } catch (error) {
    notification.warning({ description: String(error) })
    return false
  }
}

export const loadCategory = async ({ categoryID, lang }) => {
  const storedCat = storageHelper.objectFromStorage(`category_${categoryID}_${lang}`)
  if (storedCat) {
    return storedCat
  }
  const path = `single/${categoryID}`
  const url = `${BASE_URL.category}${path}`
  const response = await fetch(url, {
    method: methodType.get,
    headers: authHeaders(currentAccount(), lang),
  }).catch(error => {
    notification.warning({ description: String(error) })
    return false
  })
  try {
    const responseJson = await response.json()
    storageHelper.objectToStorage(`category_${categoryID}_${lang}`, responseJson)
    return responseJson || {}
  } catch (error) {
    notification.warning({ description: String(error) })
    return false
  }
}

export const addCategory = async ({ categoryAr, categoryEn }) => {
  const path = 'add'
  const url = `${BASE_URL.category}${path}`

  const response = await fetch(url, {
    method: methodType.post,
    headers: authHeaders(currentAccount),
    body: categoryHelper.parseForAPI({ categoryEn, categoryAr }),
  }).catch(error => {
    notification.warning({ description: String(error) })
    return false
  })
  try {
    const responseJson = await response.json()
    // storageHelper.arrayToStorage('categories', responseJson.categories)
    return responseJson || []
    // return false
  } catch (error) {
    notification.warning({ description: String(error) })
    return false
  }
}

export const deleteCategory = async categoryID => {
  storageHelper.remove('categories')
  const path = `delete/${categoryID}`
  const url = `${BASE_URL.category}${path}`

  await fetch(url, { method: methodType.delete, headers: authHeaders(currentAccount) }).catch(
    error => {
      notification.warning({ description: String(error) })
      return false
    },
  )
  try {
    storageHelper.remove(`category_${categoryID}_en`)
    storageHelper.remove(`category_${categoryID}_ar`)
  } catch (error) {
    console.log(error)
    notification.warning({ description: error.toString() })
  }
}

export const updateCategory = async ({ categoryID, categoryAr, categoryEn }) => {
  const path = `update/${categoryID}`
  const url = `${BASE_URL.category}${path}`

  const categoryForUpdate = categoryHelper.parseForAPI({ categoryEn, categoryAr })
  // JSON.stringify({
  //   description_ar: categoryAr.description,
  //   description_en: categoryEn.description,
  //   display_order: categoryEn.display_order,
  //   name_ar: categoryAr.name,
  //   name_en: categoryEn.name,
  //   parent_id: categoryEn.parent_id,
  //   seo_description_ar: categoryEn.seo_description,
  //   seo_description_en: categoryEn.seo_description,
  //   seo_keywords_ar: categoryAr.seo_keywords,
  //   seo_keywords_en: categoryEn.seo_keywords,
  //   seo_title_ar: categoryAr.seo_title,
  //   seo_title_en: categoryEn.seo_title,
  //   slug_en: categoryEn.slug,
  //   slug_ar: categoryAr.slug,
  //   type: categoryEn.type,
  // })

  console.log('category for update', categoryID, JSON.parse(categoryForUpdate), categoryForUpdate)
  await fetch(url, {
    method: methodType.put,
    headers: authHeaders(currentAccount),
    body: categoryForUpdate,
  }).catch(error => {
    console.log(error)
    notification.warning({ description: error.toString() })
    return false
  })
  try {
    storageHelper.remove(`category_${categoryID}_en`)
    storageHelper.remove(`category_${categoryID}_ar`)
    storageHelper.remove('categories', [])
    storageHelper.objectToStorage(`category_${categoryID}`, categoryForUpdate)
    window.open('/#/apps/categories', '_self')
  } catch (error) {
    console.log(error)
    notification.warning({ description: error.toString() })
  }
}

export const selectCategory = categoryID => {
  const storedCategory = storageHelper.objectFromStorage(`category_${categoryID}`)
  if (storedCategory) {
    return storedCategory
  }
  try {
    const storedCategories = storageHelper.arrayFromStorage('categories', []) || []
    const filteredCat = storedCategories.filter(item => item.category_id === Number(categoryID))[0]
    filteredCat.ar = filteredCat.translations.filter(item => item.language === 'ar')[0] || {}
    filteredCat.en = filteredCat.translations.filter(item => item.language === 'en')[0] || {}
    return filteredCat || {}
  } catch (e) {
    console.log('log', e)
    return {}
  }
}

export const addProduct = async (categoryID, productID) => {
  const path = `add-product`
  const url = `${BASE_URL.category}${path}`
  const updateCat = JSON.stringify({
    category_id: Number(categoryID),
    product_id: Number(productID),
  })

  console.log('json ', updateCat)

  await fetch(url, {
    method: methodType.post,
    headers: authHeaders(currentAccount),
    body: updateCat,
  }).catch(error => {
    console.log(error)
    notification.warning({ description: error.toString() })
    return false
  })
}
