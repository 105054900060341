import actions from './actions'

const initialState = {
  products: [],
  filterProducts: [],
  loading: false,
  productEn: {
    product_id: 'new',
    display_order: 0,
    calories: '',
    price: 0,
    active: true,
    default_image: '',
    name: '',
    description: '',
    seo_title: '',
    seo_description: '',
    seo_keywords: '',
    images: [],
    sku: '',
  },
  productAr: {
    product_id: 'new',
    display_order: 0,
    calories: '',
    price: 0,
    active: true,
    default_image: '',
    name: '',
    description: '',
    seo_title: '',
    seo_description: '',
    seo_keywords: '',
    images: [],
    sku: '',
  },
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.FILTER_PRODUCTS:
      return { ...state, filterProducts: action.payload }
    case actions.RESET_PRODUCT:
      return { ...state, productEn: initialState.productEn, productAr: initialState.productAr }
    default:
      return state
  }
}
