import React from 'react'
// import { Button } from 'antd'
import styles from './style.module.scss'

const Footer = () => (
  <div className={styles.footer}>
    <div className={styles.inner}>
      <div className={styles.bottom}>
        <div className="row">
          <div className="col-sm-6">
            {/* <a
              href="https://themeforest.net/item/clean-ui-react-admin-template/21938700"
              target="_blank"
              rel="noopener noreferrer"
              className="mr-4"
            >
              <Button type="danger">Buy Now 24$</Button>
            </a> */}
          </div>
          <div className="col-sm-6">
            <div className={styles.copyright}>
              <img
                src="resources/images/nav-logo@2x.png"
                rel="noopener noreferrer"
                alt="Mediatec Software"
              />
              <span>
                © {new Date().getFullYear()}{' '}
                <a href="http://encorejets.com/" target="_blank" rel="noopener noreferrer">
                  EncoreJets
                </a>
                <br />
                All rights reserved
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Footer
