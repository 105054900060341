import actions from './actions'

const initialState = {
  users: [],
  loading: false,
  selectedUser: {},
  searchQuery: '',
  searchUserList: [],
}

export default function usersReducer(state = initialState, action) {
  console.log('user action', action, state)
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
