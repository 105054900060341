import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'

import { login, currentAccount, logout, loadUsers } from '../../services/user'
import { startLoading, stopLoading } from '../utility'

import actions from './actions'

export function* LOGIN({ payload }) {
  const { email, password } = payload
  startLoading('user/SET_STATE')
  const success = yield call(login, email, password)
  if (success) {
    const { message } = success
    if (message) {
      notification.error({ message, description: 'Your credentials are invalid' })
      stopLoading('user/SET_STATE')
      return
    }
    notification.success({
      message: 'Logged In',
      description: 'You have successfully logged in to EncoreJets Admin!',
    })
    const { locale, role, token } = success
    const user = {
      id: success.user_id,
      name: `${success.first_name} ${success.last_name}`,
      email: success.email,
      locale,
      role,
      authorized: true,
      token,
    }
    yield put({ type: 'user/SET_STATE', payload: user })
    stopLoading('user/SET_STATE')
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  startLoading('user/SET_STATE')
  const response = yield call(currentAccount)
  if (response) {
    const { uid: id, email, photoURL: avatar } = response
    yield put({
      type: 'user/SET_STATE',
      payload: { id, name: 'Administrator', email, avatar, role: 'admin', authorized: true },
    })
  }
  stopLoading('user/SET_STATE')
}

export function* LOGOUT() {
  yield call(logout)
  yield put({ type: 'user/SET_STATE', payload: {} })
  window.open('/', '_self')
}

export function* LOAD_USERS() {
  startLoading('user/SET_STATE')
  const users = yield call(loadUsers)
  yield put({ type: 'user/SET_STATE', payload: { users } })
  stopLoading('user/SET_STATE')
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.LOAD_USERS, LOAD_USERS),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
