import actions from './actions'

const initialState = {
  categories: [],
  loading: false,
  categoryEn: {
    category_id: 'new',
    parent_id: 0,
    type: 'product',
    display_order: 0,
    active: true,
    default_image: '',
    name: '',
    description: '',
    seo_title: '',
    seo_description: '',
    seo_keywords: '',
  },
  categoryAr: {
    category_id: 'new',
    parent_id: 0,
    type: 'product',
    display_order: 0,
    active: true,
    default_image: '',
    name: '',
    description: '',
    seo_title: '',
    seo_description: '',
    seo_keywords: '',
  },
  selectedCategory: {},
}

export default function categoryReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
