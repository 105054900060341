import { all, takeEvery, put, call } from 'redux-saga/effects'
import { startLoading, stopLoading } from '../utility'

import {
  loadLocations,
  selectLocation,
  createLocation,
  updateLocation,
} from '../../services/locations'
import actions from './actions'

export function* LOAD_LOCATIONS() {
  startLoading('locations/SET_STATE')
  const locations = yield call(loadLocations)
  console.log('log saga', locations)
  if (locations) {
    yield put({ type: 'locations/SET_STATE', payload: { locations } })
  }
  stopLoading('locations/SET_STATE')
}

export function* LOAD_LOCATION({ payload: { locationID } }) {
  startLoading('locations/SET_STATE')
  const selectedLocation = selectLocation(locationID)
  console.log('selected', selectedLocation)
  yield put({ type: 'locations/SET_STATE', payload: { selectedLocation } })

  stopLoading('locations/SET_STATE')
}

export function* UPDATE_LOCATION({ payload: { locationID, location } }) {
  startLoading('locations/SET_STATE')
  yield updateLocation(locationID, location)
  stopLoading('locations/SET_STATE')
}

export function* CREATE_LOCATION({ payload: { location } }) {
  startLoading('locations/SET_STATE')
  yield createLocation(location)
  stopLoading('locations/SET_STATE')
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_LOCATIONS, LOAD_LOCATIONS)])
  yield all([takeEvery(actions.LOAD_LOCATION, LOAD_LOCATION)])
  yield all([takeEvery(actions.CREATE_LOCATION, CREATE_LOCATION)])
  yield all([takeEvery(actions.UPDATE_LOCATION, UPDATE_LOCATION)])
}
