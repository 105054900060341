const actions = {
  SET_STATE: 'orders/SET_STATE',
  LOAD_ORDERS: 'orders/LOAD_ORDERS',
  LOAD_ORDER: 'orders/LOAD_ORDER',
  UPDATE_ORDER: 'orders/UPDATE_ORDER',
  FILTER_ORDERS: 'orders/FILTER_ORDERS',
  UPDATE_ORDER_STATUS: 'orders/UPDATE_ORDER_STATUS',
  LOAD_RECENT_ORDERS: 'orders/LOAD_RECENT_ORDERS',
}

export default actions
