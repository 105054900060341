import { notification } from 'antd'
import { BASE_URL, authHeaders, methodType, urlParams } from './config'
import { storageHelper } from './helpers'
import { currentAccount } from './user'

export const loadProducts = async (query = {}) => {
  // category: 1, search: 'en', active: true
  const storedProducts = storageHelper.arrayFromStorage('products')
  if (storedProducts && storedProducts.length > 0) {
    return storedProducts
  }
  const path = 'all?'
  const url = `${BASE_URL.product}${path}${urlParams(query)}`
  const response = await fetch(url, {
    method: methodType.get,
    headers: authHeaders(await currentAccount()),
  }).catch(error => {
    notification.warning({ description: error.toString() })
    return false
  })
  try {
    const responseJson = await response.json()
    console.log('products loaded', responseJson)
    if (responseJson && responseJson.products) {
      storageHelper.arrayToStorage('products', responseJson.products)
      return responseJson.products
    }
    return []
  } catch (error) {
    notification.warning({ description: error })
    return false
  }
}

export const loadStoredProduct = async productID => {
  const products = storageHelper
    .arrayFromStorage('products')
    .filter(product => Number(product.product_id) === Number(productID))
  return products[0] || null
}

export const loadSingleProduct = async ({ productID, lang }) => {
  const products = storageHelper
    .arrayFromStorage('products')
    .filter(product => Number(product.product_id) === Number(productID))
  console.log(products)
  const storedProduct = storageHelper.objectFromStorage(`product_${productID}_${lang}`)
  if (storedProduct) {
    return storedProduct
  }
  const path = `single/${productID}`
  const url = `${BASE_URL.product}${path}`
  const response = await fetch(url, {
    method: methodType.get,
    headers: authHeaders(currentAccount(), lang),
  }).catch(error => {
    notification.warning({ description: error })
    return false
  })
  try {
    const responseJson = await response.json()
    storageHelper.objectToStorage(`product_${productID}_${lang}`, responseJson)
    return responseJson || {}
  } catch (error) {
    notification.warning({ description: error.toString() })
    return false
  }
}

export const updateProduct = async ({ productID, product }) => {
  const path = `update/${productID}`
  const url = `${BASE_URL.product}${path}`
  clearStorage(productID)
  await fetch(url, {
    method: methodType.put,
    headers: authHeaders(currentAccount),
    body: JSON.stringify(product),
  }).catch(error => {
    notification.warning({ description: error.toString() })
    return false
  })
  try {
    clearStorage(productID)
    window.open('/#/apps/products', '_self')
  } catch (error) {
    notification.warning({ description: error.toString() })
  }
}

export const createProduct = async ({ productID, product }) => {
  const url = `${BASE_URL.product}add`
  clearStorage(productID)
  await fetch(url, {
    method: methodType.post,
    headers: authHeaders(currentAccount),
    body: JSON.stringify(product),
  }).catch(error => {
    notification.warning({ description: error.toString() })
    return false
  })
  try {
    clearStorage(productID)
    window.open('/#/apps/products', '_self')
  } catch (error) {
    notification.warning({ description: error.toString() })
  }
}

// const parseProduct = (productEn, productAr) => {
//   /**
//    *   "display_order" : 1345,
//     "calories" : "22122",
//     "price" : 6.13,
//     "sku": 123,
//     "translations" : {
//         "en" : {
//             "name" : "1",
//             "description" : "2",
//             "seo_title" : "3",
//             "seo_keywords" : "4",
//             "seo_description" : "5"
//         },
//         "ar" : {
//             "name" : "11231231",
//             "description" : "21231231",
//             "seo_title" : "41231231",
//             "seo_keywords" : "a,b,c,d,e,f,g",
//             "seo_description" : "testdesc123123131231"
//         }

//     }
//    */
//   const productObject = JSON.stringify({
//     display_order: productEn.display_order,
//     calories: productEn.calories,
//     price: productEn.price,
//     sku: productEn.sku,
//     translations: {
//       en: {
//         name: productEn.name,
//         description: productEn.description,
//         seo_title: productEn.seo_title,
//         seo_keywords: productEn.seo_keywords,
//         seo_description: productEn.seo_description,
//       },
//       ar: {
//         name: productAr.name,
//         description: productAr.description,
//         seo_title: productAr.seo_title,
//         seo_keywords: productAr.seo_keywords,
//         seo_description: productAr.seo_description,
//       },
//     },
//   })
//   console.log('productObject', productObject)
//   return productObject
// }

const clearStorage = productID => {
  storageHelper.remove('products')
  storageHelper.objectToStorage(`product_${productID}_en`)
  storageHelper.objectToStorage(`product_${productID}_ar`)
}

export const uploadImage = (productID, image) => {
  // const url = `${BASE_URL.product}product/upload-image/${productID}`
  // // const formData = (FormData())
  // // formData.append('myimage', event.target.myimage.files[0])
  //
  // await fetch(url, {
  //   method: methodType.post,
  //   headers: authHeaders(currentAccount),
  //   body: {
  //     file: image,
  //   },
  // }).catch(error => {
  //   notification.warning({
  //     description: error.toString(),
  //   })
  //   return false
  // })
  // try {
  //   // clearStorage(productID)
  //   // window.open('/#/apps/products', '_self')
  // } catch (error) {
  //   notification.warning({
  //     description: error.toString(),
  //   })
  // }
  console.log('log', productID, image)
}
