import { all, takeEvery, put, call } from 'redux-saga/effects'

import {
  loadCategories,
  selectCategory,
  addCategory,
  deleteCategory,
  updateCategory,
  addProduct,
} from '../../services/categories'
import { startLoading, stopLoading } from '../utility'

import actions from './actions'

export function* LOAD_CATEGORIES({ payload: query }) {
  startLoading('categories/SET_STATE')
  const categories = yield call(() => loadCategories(query))
  if (categories) {
    yield put({ type: 'categories/SET_STATE', payload: { categories } })
  }
  stopLoading('categories/SET_STATE')
}

export function* LOAD_CATEGORY({ payload: { categoryID } }) {
  startLoading('categories/SET_STATE')
  const selectedCategory = selectCategory(categoryID)
  yield put({ type: 'categories/SET_STATE', payload: { selectedCategory } })

  stopLoading('categories/SET_STATE')
}

export function* DELETE_CATEGORY({ payload: { categoryID } }) {
  startLoading('categories/SET_STATE')
  yield call(() => deleteCategory(categoryID))
  yield put({ type: 'categories/SET_STATE', payload: { categories: [] } })

  stopLoading('categories/SET_STATE')
}

export function* UPDATE_CATEGORY({ payload: { categoryID, categoryEn, categoryAr } }) {
  yield call(() => updateCategory({ categoryID, categoryAr, categoryEn }))
  yield put({ type: 'categories/SET_STATE', payload: { categoryAr, categoryEn } })
}

export function* CREATE_CATEGORY({ payload: { categoryEn, categoryAr } }) {
  yield call(() => addCategory({ categoryAr, categoryEn }))
  yield put({ type: 'categories/SET_STATE', payload: { categoryAr, categoryEn } })
}

export function* SET_PRODUCT({ payload: { categoryID, productID } }) {
  yield call(() => addProduct(categoryID, productID))
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_CATEGORIES, LOAD_CATEGORIES),
    takeEvery(actions.LOAD_CATEGORY, LOAD_CATEGORY),
    takeEvery(actions.SET_PRODUCT, SET_PRODUCT),
    takeEvery(actions.DELETE_CATEGORY, DELETE_CATEGORY),
    takeEvery(actions.UPDATE_CATEGORY, UPDATE_CATEGORY),
    takeEvery(actions.CREATE_CATEGORY, CREATE_CATEGORY),
  ])
}
