import { notification } from 'antd'
import { BASE_URL, authHeaders, methodType, urlParams } from './config'
import { storageHelper } from './helpers'
import { currentAccount } from './user'

const currentAcc = currentAccount()

export const loadLocations = async (query = {}) => {
  // category: 1, search: 'en', active: true
  const storedLocations = storageHelper.arrayFromStorage('locations')
  if (storedLocations && storedLocations.length > 0) {
    return storedLocations
  }
  const url = `${BASE_URL.location}all${urlParams(query)}`
  const response = await fetch(url, {
    method: methodType.get,
    headers: authHeaders(currentAccount),
  }).catch(error => {
    notification.warning({ description: error.toString() })
    return false
  })
  try {
    const responseJson = await response.json()
    if (responseJson && responseJson.locations) {
      storageHelper.arrayToStorage('locations', responseJson.locations)
      return responseJson.locations
    }
    return []
  } catch (error) {
    notification.warning({ description: error.toString() })
    return false
  }
}

export const selectLocation = locationID => {
  const storedLocation = storageHelper.objectFromStorage(`location_${locationID}`)
  if (storedLocation) {
    return storedLocation
  }
  try {
    const storedLocations = storageHelper.arrayFromStorage('locations', []) || []
    const filteredLocation = storedLocations.filter(
      item => item.store_location_id === Number(locationID),
    )[0]
    const en = filteredLocation.translations.filter(lang => lang.language === 'en')[0]
    const ar = filteredLocation.translations.filter(lang => lang.language === 'ar')[0]
    filteredLocation.en = en
    filteredLocation.ar = ar
    return filteredLocation || {}
  } catch (e) {
    console.log('log', e)
    return null
  }
}

export const updateLocation = async (locationID, location) => {
  const path = `update/${locationID}`
  const url = `${BASE_URL.location}${path}`
  console.log('updateLocation', url, await currentAcc, locationID, location)
  try {
    const result = await fetch(url, {
      method: methodType.put,
      headers: authHeaders(currentAcc),
      body: location,
    }).catch(error => {
      console.log(error)
      notification.warning({ description: error.toString() })
      return false
    })
    if (result) {
      storageHelper.remove('locations', [])
      storageHelper.objectToStorage(`location_${locationID}`, location)
      window.open('/#/apps/locations', '_self')
    }
  } catch (error) {
    console.log(error)
    notification.warning({ description: error.toString() })
  }
}
export const createLocation = async location => {
  const path = `update/add`
  const url = `${BASE_URL.location}${path}`
  await fetch(url, {
    method: methodType.put,
    headers: authHeaders(currentAcc),
    body: location,
  }).catch(error => {
    console.log(error)
    notification.warning({ description: error.toString() })
    return false
  })
  try {
    storageHelper.remove('locations', [])
    window.open('/#/apps/locations', '_self')
  } catch (error) {
    console.log(error)
    notification.warning({ description: error.toString() })
  }
}
