import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import products from './products/reducers'
import categories from './categories/reducers'
import orders from './orders/reducers'
import locations from './locations/reducers'
import users from './users/reducers'
import destinations from './destinations/reducers'
import brokers from './brokers/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    products,
    categories,
    orders,
    locations,
    users,
    destinations,
    brokers
  })
