import actions from './actions'

const initialState = {
  destinations: [],
  loading: false,
  destination: {},
  selectedDestination: {},
}

export default function destinationReducer(state = initialState, action) {
  if (action.type === actions.SET_STATE) {
    return { ...state, ...action.payload }
  }
  return state
}
