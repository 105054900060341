const actions = {
  SET_STATE: 'products/SET_STATE',
  LOAD_PRODUCTS: 'products/LOAD_PRODUCTS',
  LOAD_PRODUCT: 'products/LOAD_PRODUCT',
  CREATE_PRODUCT: 'products/CREATE_PRODUCT',
  UPDATE_PRODUCT: 'products/UPDATE_PRODUCT',
  FILTER_PRODUCTS: 'products/FILTER_PRODUCTS',
  RESET_PRODUCT: 'products/RESET_PRODUCT',
  UPLOAD_PRODUCT_IMAGE: 'products/UPLOAD_PRODUCT_IMAGE',
}

export default actions
