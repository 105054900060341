import { all, takeEvery, put, call } from 'redux-saga/effects'
import { loadDestinations, selectDestination, update } from '../../services/destinations'
import actions from './actions'

import { startLoading, stopLoading } from '../utility'

export function* LOAD_DESTINATIONS() {
  startLoading('destinations/SET_STATE')
  const destinations = yield call(loadDestinations)
  if (destinations) {
    yield put({ type: 'destinations/SET_STATE', payload: { destinations } })
  }
  stopLoading('destinations/SET_STATE')
}

export function* LOAD_DESTINATION({ payload: { destinationID } }) {
  startLoading('destinations/SET_STATE')
  const selectedDestination = yield call(() => selectDestination({ destinationID }))
  yield put({
    type: 'destinations/SET_STATE',
    payload: { selectedDestination },
  })
  stopLoading('destinations/SET_STATE')
}

export function* UPDATE_DESTINATION({ payload: { selectedDestination } }) {
  startLoading('destinations/SET_STATE')
  yield call(() => update(selectedDestination))
  stopLoading('destinations/SET_STATE')
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_DESTINATIONS, LOAD_DESTINATIONS)])
  yield all([takeEvery(actions.LOAD_DESTINATION, LOAD_DESTINATION)])
  // yield all([takeEvery(actions.CREATE_LOCATION, CREATE_LOCATION)])
  yield all([takeEvery(actions.UPDATE_DESTINATION, UPDATE_DESTINATION)])
}
