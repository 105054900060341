import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import products from './products/sagas'
import categories from './categories/sagas'
import users from './users/sagas'
import orders from './orders/sagas'
import locations from './locations/sagas'
import destinations from './destinations/sagas'
import brokers from './brokers/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    settings(),
    products(),
    categories(),
    users(),
    orders(),
    locations(),
    destinations(),
    brokers(),
  ])
}
