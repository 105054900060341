import { notification } from 'antd'
import { authHeaders, BASE_URL, methodType, urlParams } from './config'
import { storageHelper } from './helpers'
import { currentAccount } from './user'

export const loadOrders = async (query = { status: 'REVIEW' }) => {
  // const storedOrders = storageHelper.arrayFromStorage('orders')
  // if (storedOrders && storedOrders.length > 0) {
  //   return storedOrders
  // }

  const path = '?'
  const url = `${BASE_URL.orders}${path}${urlParams(query)}`
  // const { result } = currentAccount
  const response = await fetch(url, {
    method: methodType.get,
    headers: authHeaders(await currentAccount()),
  }).catch(error => {
    notification.warning({ description: error.toString() })
    return false
  })
  try {
    const responseJson = await response.json()
    const { orders } = responseJson
    if (orders && orders.length > 0) {
      // storageHelper.arrayToStorage('orders', responseJson.orders)
      return responseJson.orders
    }
    return []
  } catch (error) {
    notification.warning({ description: error.toString() })
    return false
  }
}

export const loadSingleOrder = async ({ orderID, lang }) => {
  const storedOrder = storageHelper.objectFromStorage(`order_${orderID}_${lang}`)
  if (storedOrder) {
    return storedOrder
  }
  const path = `single/${orderID}`
  const url = `${BASE_URL.order}${path}`
  const response = await fetch(url, {
    method: methodType.get,
    headers: authHeaders(currentAccount(), lang),
  }).catch(error => {
    notification.warning({ description: error })
    return false
  })
  try {
    const responseJson = await response.json()
    storageHelper.objectToStorage(`order_${orderID}_${lang}`, responseJson)
    return responseJson || {}
  } catch (error) {
    notification.warning({ description: error.toString() })
    return false
  }
}

export const updateOrder = async ({ orderID, orderAr, orderEn }) => {
  const path = `update/${orderID}`
  const url = `${BASE_URL.order}${path}`
  await fetch(url, {
    method: methodType.put,
    headers: authHeaders(currentAccount),
    body: JSON.stringify({
      calories: orderEn.calories,
      description_ar: orderAr.description,
      description_en: orderEn.description,
      display_order: orderEn.display_order,
      name_ar: orderAr.name,
      name_en: orderEn.name,
      price: orderEn.price,
      seo_description_ar: orderAr.seo_description,
      seo_description_en: orderEn.seo_description,
      seo_keywords_ar: orderAr.seo_keywords,
      seo_keywords_en: orderEn.seo_keywords,
      seo_title_ar: orderAr.seo_title,
      seo_title_en: orderEn.seo_title,
    }),
  }).catch(error => {
    console.log(error)
    notification.warning({ description: error.toString() })
    return false
  })
  try {
    storageHelper.remove('orders')
    storageHelper.objectToStorage(`order_${orderID}_en`)
    storageHelper.objectToStorage(`order_${orderID}_ar`)
    window.open('/#/apps/orders', '_self')
  } catch (error) {
    console.log(error)
    notification.warning({ description: error.toString() })
  }
}

export const updateOrderStatus = async (orderID, status, reason = 'Rejected by admin') => {
  const path = `/${orderID}/approve`
  const url = `${BASE_URL.orders}${path}`
  const updateCat = { approve: status === 'approve' }
  if (status !== 'approve') {
    updateCat.reject_reason = reason
  }
  const currentAcc = await currentAccount()

  await fetch(url, {
    method: methodType.put,
    headers: authHeaders(currentAcc),
    body: JSON.stringify(updateCat),
  }).catch(error => {
    console.log(error)
    notification.warning({ description: error.toString() })
    return false
  })
}

export const loadRecentOrders = async () => {
  const path = 'recent'
  const currentAcc = await currentAccount()
  const response = await fetch(`${BASE_URL.orders}${path}`, {
    method: methodType.get,
    headers: authHeaders(currentAcc),
  }).catch(error => {
    console.log(error)
    notification.warning({ description: error.toString() })
    return []
  })
  try {
    const responseJson = await response.json()
    if (responseJson && responseJson.orders) {
      return responseJson.orders
    }
    return []
  } catch (error) {
    console.log(error)
    notification.warning({ description: error.toString() })
    return []
  }
}
