export const sortAlpha = (a, b) => {
  if (a < b) {
    return -1
  }
  if (a > b) {
    return 1
  }
  return 0
}

export const nameForItem = (item, nameKey) => {
  try {
    if (typeof item.name === 'string') {
      return item.name
    }
    if (item.translations) {
      const enLang = item.translations.filter(langItem => langItem.language === 'en')[0]
      if (enLang) {
        return enLang[nameKey] || ''
      }
    }
    return []
  } catch (e) {
    console.log('log, issue with getting item name', e)
    return []
  }
}

export const parseForDropMenu = (data = [], idKey = 'id', nameKey = '') => {
  return data.map(item => ({ value: item[idKey], name: nameForItem(item, nameKey) }))
}

export const translation = (product, language = 'en') =>
  product.translations.filter(lang => lang.language === language)[0] || {}
