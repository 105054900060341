import { all, takeEvery, put, call } from 'redux-saga/effects'

import { loadUsers, loadSingleUser, searchUsers } from '../../services/users'
import { startLoading, stopLoading } from '../utility'

import actions from './actions'

export function* LOAD_USERS() {
  startLoading(actions.SET_STATE)
  const users = yield call(loadUsers)
  if (users && users.length > 0) {
    console.log('users', users)
    yield put({ type: 'users/SET_STATE', payload: { users } })
  }
  stopLoading(actions.SET_STATE)
}

export function* LOAD_USER({ payload: { userID } }) {
  startLoading('users/SET_STATE')
  const selectedUser = yield call(() => loadSingleUser({ userID }))
  yield put({
    type: 'users/SET_STATE',
    payload: { selectedUser },
  })
  stopLoading('users/SET_STATE')
}

export function* SEARCH_USER({ payload: { query } }) {
  console.log('search user', query)
  startLoading('users/SET_STATE')
  yield put({ type: actions.SET_STATE, payload: { searchQuery: query }})
  const searchUserList = yield call(() => searchUsers(query))
  yield put({ type: 'users/SET_STATE', payload: { searchUserList } })
  stopLoading('users/SET_STATE')
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_USERS, LOAD_USERS),
    takeEvery(actions.LOAD_USER, LOAD_USER),
    takeEvery(actions.SEARCH_USER, SEARCH_USER)])
}
