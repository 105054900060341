export const parseForAPI = ({ categoryAr, categoryEn }) => {
  const category = {
    type: categoryEn.type || 'product',
    display_order: categoryEn.display_order,
    active: categoryEn.active,
    translations: [],
  }
  category.translations.push({
    description: categoryEn.description,
    name: categoryEn.name,
    seo_description: categoryEn.seo_description,
    seo_keywords: categoryEn.seo_keywords,
    seo_title: categoryEn.seo_title,
    language: 'en',
    slug: categoryEn.slug || slugify(categoryEn.name),
  })
  category.translations.push({
    description: categoryAr.description,
    name: categoryAr.name,
    seo_description: categoryAr.seo_description,
    seo_keywords: categoryAr.seo_keywords,
    seo_title: categoryAr.seo_title,
    language: 'ar',
    slug: categoryAr.slug || slugify(categoryAr.name),
  })
  return JSON.stringify(category)
}

export const parseFromAPI = () => {}

export const slugify = name => {
  return name.toLowerCase().replace(/ |_/g, '-')
}

export const categoryIndex = categories => {
  const obj = {}
  categories.map(category => {
    const catEn = category.translations.filter(item => item.language === 'en')[0] || {}
    obj[category.category_id] = catEn.name
    return category
  })
  return obj
}

export const categorySelectList = categories => {
  return categories.map(category => {
    const catEn = category.translations.filter(item => item.language === 'en')[0] || {}
    return { value: category.category_id, name: catEn.name }
  })
}
