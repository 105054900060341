const envURL = {
  dev: 'http://localhost:8000/api/',
  production: 'https://api.encorejets.com/api/',
}

export const routeServer = envURL.dev /// process.env.NODE_ENV === 'development' ? envURL.production : envURL.production
// 'http://localhost:8000/api/'

export const BASE_URL = {
  user: `${routeServer}admin/users/`,
  auth: `${routeServer}auth/`,
  // product: 'https://product-service.munchv2.munchbakery.com/v1/admin/product/',
  // category: 'https://product-service.munchv2.munchbakery.com/v1/admin/category/',
  // static: 'https://static-service.munchv2.munchbakery.com/v1/',
  // image: 'https://images.munchv2.munchbakery.com/product-images/:image',
  orders: `${routeServer}admin/orders/`,
  destinations: `${routeServer}trip/`,
  // brokers: `${routeServer}brokers/`,
  // location: 'https://product-service.munchv2.munchbakery.com/v1/admin/locations/',
}

export const methodType = {
  post: 'POST',
  get: 'GET',
  patch: 'PATCH',
  delete: 'DELETE',
  put: 'PUT',
}

export const defaultHeaders = lang => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }

  if (lang) {
    headers['Accept-Language'] = lang || 'en'
  }
  return headers
}

export const authHeaders = (user, lang) => {
  if (user && user.token) {
    return {
      ...defaultHeaders(lang),
      Authorization: `Bearer ${user.token}`,
    }
  }
  return { ...defaultHeaders(lang) }
}

export const urlParams = (obj = {}) =>
  Object.entries(obj)
    .map(([key, val]) => `${key}=${val}`)
    .join('&')

export const SESSION_USER_EXPIRY = 60
export const CACHE_EXPIRY = 15
