import { all, takeEvery, put, call } from 'redux-saga/effects'
import { startLoading, stopLoading } from '../utility'
import { dataHelper } from '../../services/helpers'

import {
  loadProducts,
  loadSingleProduct,
  updateProduct,
  createProduct,
  loadStoredProduct,
  uploadImage,
} from '../../services/products'
import actions from './actions'

export function* LOAD_PRODUCTS() {
  startLoading('products/SET_STATE')
  const products = yield call(loadProducts)
  if (products) {
    yield put({ type: 'products/SET_STATE', payload: { products } })
  }
  stopLoading('products/SET_STATE')
}

export function* LOAD_PRODUCT({ payload: { productID } }) {
  startLoading('products/SET_STATE')
  const product = yield call(() => loadStoredProduct(productID))
  const en = dataHelper.translation(product, 'en')
  const ar = dataHelper.translation(product, 'ar')
  let payload = { productEn: { ...product, ...en }, productAr: { ...product, ...ar } }

  if (!product) {
    const resultEn = yield call(() => loadSingleProduct({ productID, lang: 'en' }))
    const resultAr = yield call(() => loadSingleProduct({ productID, lang: 'ar' }))
    payload = { productEn: { ...resultEn }, productAr: { ...resultAr } }
  }
  yield put({ type: 'products/SET_STATE', payload })
  stopLoading('products/SET_STATE')
}

export function* UPDATE_PRODUCT({ payload: { productID, product } }) {
  startLoading('products/SET_STATE')
  yield call(() => updateProduct({ productID, product }))
  stopLoading('products/SET_STATE')
}

export function* CREATE_PRODUCT({ payload: { productID, product } }) {
  startLoading('products/SET_STATE')
  yield call(() => createProduct({ productID, product }))
  stopLoading('products/SET_STATE')
}

export function* UPLOAD_PRODUCT_IMAGE({ payload: { productID, image } }) {
  yield call(() => uploadImage({ productID, image }))
  console.log('log', image)
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_PRODUCTS, LOAD_PRODUCTS),
    takeEvery(actions.LOAD_PRODUCT, LOAD_PRODUCT),
    takeEvery(actions.UPDATE_PRODUCT, UPDATE_PRODUCT),
    takeEvery(actions.CREATE_PRODUCT, CREATE_PRODUCT),
    takeEvery(actions.UPLOAD_PRODUCT_IMAGE, UPLOAD_PRODUCT_IMAGE),
  ])
}
