const actions = {
  SET_STATE: 'categories/SET_STATE',
  LOAD_CATEGORIES: 'categories/LOAD_CATEGORIES',
  LOAD_CATEGORY: 'categories/LOAD_CATEGORY',
  SET_PRODUCT: 'categories/SET_PRODUCT',
  DELETE_CATEGORY: 'categories/DELETE_CATEGORY',
  UPDATE_CATEGORY: 'categories/UPDATE_CATEGORY',
  CREATE_CATEGORY: 'categories/CREATE_CATEGORY',
}

export default actions
