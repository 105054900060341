import { notification } from 'antd'
import { authHeaders, BASE_URL, methodType, urlParams } from './config'
import { storageHelper } from './helpers'
import { currentAccount } from './user'

export const loadDestinations = async (query = {}) => {
  // category: 1, search: 'en', active: true
  const storedDestinations = storageHelper.arrayFromStorage('destinations')
  if (storedDestinations && storedDestinations.length > 0) {
    return storedDestinations
  }
  const url = `${BASE_URL.destinations}list${urlParams(query)}`
  const response = await fetch(url, {
    method: methodType.get,
    headers: authHeaders(await currentAccount()),
  }).catch(error => {
    notification.warning({ description: error.toString() })
    return false
  })
  try {
    const destinations = await response.json()

    if (destinations) {
      storageHelper.arrayToStorage('destinations', destinations)
      return destinations
    }
    return []
  } catch (error) {
    notification.warning({ description: error.toString() })
    return false
  }
}

export const selectDestination = ({ destinationID }) => {
  const storedDestination = storageHelper.objectFromStorage(`destination_${destinationID}`)
  if (storedDestination) {
    return storedDestination
  }
  console.log('destinationID', destinationID)
  try {
    const storedDestinations = storageHelper.arrayFromStorage('destinations', []) || []
    const filteredDestination = storedDestinations.filter(item => item._id === destinationID)[0]
    if (filteredDestination) {
      storageHelper.objectToStorage(`destination_${destinationID}`, filteredDestination)
    }
    return filteredDestination || {}
  } catch (e) {
    console.log('log', e)
    return null
  }
}

export const update = async destination => {
  const destinationID = destination._id
  const path = `update/${destinationID}`
  const url = `${BASE_URL.destinations}${path}`
  await fetch(url, {
    method: methodType.put,
    headers: authHeaders(await currentAccount()),
    body: JSON.stringify({
      ...destination,
      startDate: destination.startDate.toISOString(),
      endDate: destination.endDate.toISOString(),
    }),
  }).catch(error => {
    console.log(error)
    notification.warning({ description: error.toString() })
    return false
  })
  try {
    storageHelper.remove('destinations', [])
    storageHelper.objectToStorage(`destination_${destinationID}`, destination)
    window.open('/#/apps/destinations', '_self')
  } catch (error) {
    console.log(error)
    notification.warning({ description: error.toString() })
  }
}
