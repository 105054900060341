import { notification } from 'antd'
import { BASE_URL, authHeaders, methodType } from './config'
import { storageHelper } from './helpers'
import { currentAccount } from './user'

export const loadBrokers = async () => {
  const storedBrokers = storageHelper.arrayFromStorage('brokers')
  if (storedBrokers) {
    return storedBrokers
  }
  const url = `${BASE_URL.brokers}`
  const response = await fetch(url, {
    method: methodType.get,
    headers: authHeaders(await currentAccount()),
  }).catch(error => {
    notification.warning({
      description: error,
    })
    return false
  })
  try {
    const responseJson = await response.json()
    if (responseJson && responseJson.brokers) {
      storageHelper.arrayToStorage('brokers', responseJson.brokers)
      return responseJson.brokers
    }
    return false
  } catch (error) {
    notification.warning({ description: error.message })
    return false
  }
}

export const loadSingleBroker = async ({ brokerID }) => {
  const storedBrokers = storageHelper.arrayFromStorage('brokers')
  const filteredBrokers = storedBrokers.filter(fBroker => fBroker._id === brokerID)
  return filteredBrokers.length > 0 ? filteredBrokers[0] : null
}

export const searchBrokers = async ({ query }) => {
  console.log(query)
  const url = `${BASE_URL.broker}search-broker?=${query}`
  const response = await fetch(url, {
    method: methodType.get,
    headers: authHeaders(await currentAccount()),
  }).catch(error => {
    notification.warning({
      description: error,
    })
    return []
  })
  try {
    const responseJson = await response.json()
    if (responseJson && responseJson.brokers) {
      return responseJson.brokers
    }
    return []
  } catch (error) {
    notification.warning({ description: error.message })
    return false
  }
}