import { notification } from 'antd'
import { BASE_URL, authHeaders, methodType } from './config'
import { storageHelper } from './helpers'
import { currentAccount } from './user'

export const loadUsers = async () => {
  const storedUsers = storageHelper.arrayFromStorage('users')
  if (storedUsers) {
    return storedUsers
  }
  const url = `${BASE_URL.user}`
  const response = await fetch(url, {
    method: methodType.get,
    headers: authHeaders(await currentAccount()),
  }).catch(error => {
    notification.warning({
      description: error,
    })
    return false
  })
  try {
    const responseJson = await response.json()
    if (responseJson && responseJson.users) {
      storageHelper.arrayToStorage('users', responseJson.users)
      return responseJson.users
    }
    return false
  } catch (error) {
    notification.warning({ description: error.message })
    return false
  }
}

export const loadSingleUser = async ({ userID }) => {
  const storedUsers = storageHelper.arrayFromStorage('users')
  const filteredUsers = storedUsers.filter(fUser => fUser._id === userID)
  return filteredUsers.length > 0 ? filteredUsers[0] : null
}

export const searchUsers = async ({ query }) => {
  console.log(query)
  const url = `${BASE_URL.user}search-user?=${query}`
  const response = await fetch(url, {
    method: methodType.get,
    headers: authHeaders(await currentAccount()),
  }).catch(error => {
    notification.warning({
      description: error,
    })
    return []
  })
  try {
    const responseJson = await response.json()
    if (responseJson && responseJson.users) {
      return responseJson.users
    }
    return []
  } catch (error) {
    notification.warning({ description: error.message })
    return false
  }
}
