import actions from './actions'

const initialState = {
  locations: [],
  loading: false,
  location: {
    category_id: 0,
    default_image: '',
    description: '',
    display_order: 0,
    is_central_location: true,
    language_id: 0,
    latitude: 0,
    longitude: 0,
    name: '',
    parent_id: 0,
    phone_number: '',
    seo_description: '',
    seo_keywords: '',
    seo_title: '',
    slug: '',
    store_location_id: 0,
    type: '',
    working_hours: [{ time_from: '', time_to: '' }],
  },
  selectedLocation: {},
}

export default function userReducer(state = initialState, action) {
  if (action.type === actions.SET_STATE) {
    return { ...state, ...action.payload }
  }
  return state
}
