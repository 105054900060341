import { notification } from 'antd'
import { BASE_URL, defaultHeaders, authHeaders, methodType, SESSION_USER_EXPIRY } from './config'
import { storageHelper } from './helpers'

export const login = async (email, password) => {
  const response = await fetch(`${BASE_URL.auth}login`, {
    method: methodType.post,
    headers: defaultHeaders(),
    body: JSON.stringify({ email, password }),
  }).catch(error => {
    console.log(error)
    notification.warning({ description: String(error) })
    return false
  })

  try {
    console.log('response', response)
    const { error, success } = await response.json()
    if (error) {
      notification.warning({ description: String(error) })
      return false
    }
    const user = { ...success }
    if (user) {
      storageHelper.objectToStorage('currentUser', user, SESSION_USER_EXPIRY) // store to localstorage with expiry date
    }
    return user || false
  } catch (error) {
    notification.warning({ description: String(error) })
    return false
  }
}

export const currentAccount = async () => storageHelper.objectFromStorage('currentUser')

export const logout = async () => storageHelper.removeAll()

export const loadUsers = async () => {
  const url = `${BASE_URL.user}all`
  const response = await fetch(url, {
    method: methodType.get,
    headers: authHeaders(currentAccount()),
  }).catch(error => {
    notification.warning({ description: error.toString() })
    return false
  })

  try {
    const { users } = await response.json()
    if (users) {
      storageHelper.objectToStorage('users', users, 60) // store to localstorage with expiry date
    }
    return users || []
  } catch (error) {
    notification.warning({ description: error.toString() })
    return false
  }
}
